import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import meter1 from '../assets/img/meter1.svg';
import meter2 from '../assets/img/meter2.svg';
import meter3 from '../assets/img/meter3.svg';
import colorSharp from '../assets/img/color-sharp.png'

const Skills = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <section className='skill' id='skills'>
            <Container>
                <Row>
                    <Col>
                        <div>
                            <h2>Skills</h2>
                            <p>Web Applications · HTML · HTML5 · Cascading Style Sheets CSS · Bootstrap · WordPress · JavaScript · Databases · MySQL · PhpMyAdmin · Data Structures · C++ · C Programming Language · Responsive Web Design · Communication · Presentation Skills</p><br/>
                            <Carousel responsive={responsive} infinite={true} className='skill-slider'>
                                <div className='item'>
                                    <img src={meter1} alt='image'/>
                                    <h5>Html & Css & Js</h5>
                                </div>
                                <div className='item'>
                                    <img src={meter2} alt='image'/>
                                    <h5>Angular </h5>
                                </div>
                                <div className='item'>
                                    <img src={meter3} alt='image'/>
                                    <h5>Bootstrap</h5>
                                </div>
                                <div className='item'>
                                    <img src={meter1} alt='image'/>
                                    <h5>Firebase</h5>
                                </div>
                                <div className='item'>
                                    <img src={meter1} alt='image'/>
                                    <h5>React JS</h5>
                                </div>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img className='background-image-left' src={colorSharp} />
        </section>
    );
}

export default Skills;
