import logo from './logo.svg';
import './App.css';
import NavBar from './components/NavBar';
// import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Banner from './components/Banner';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Footer from './components/Footer';
import NewLetter from './components/NewLetter';
import Contect from './components/Contect';

function App() {
  return (
    <div className="App">
        <NavBar/> 
        <Banner/>
        <Skills />
        <Projects/>
        <Contect/>
        {/* <NewLetter/> */}
        <Footer />
      
    </div>
  );
}

export default App;
